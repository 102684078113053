@media only screen and (max-width : 1024px) {

}

@media only screen and (max-width : 768px) {
  h1 {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width : 450px) {

}

@media only screen and (max-width: 350px) {

}

@media only screen
  and (min-device-width: 350px)
  and (max-device-width: 767px)
  and (-webkit-min-device-pixel-ratio: 2){


}

@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px) {

}

