.rotateme{
    animation-name: rotateme;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

  -webkit-animation-name: rotateme;
    -webkit-animation-duration: 30s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

  -moz-animation-name: rotateme;
    -moz-animation-duration: 30s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

  -ms-animation-name: rotateme;
    -ms-animation-duration: 30s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

  -o-animation-name: rotateme;
    -o-animation-duration: 30s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@keyframes rotateme {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@-webkit-keyframes rotateme {
    0% {
        -webkit-transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(45deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}
@-moz-keyframes rotateme {
    0% {
        -moz-transform: rotate(0deg);
    }
    50% {
        -moz-transform: rotate(45deg);
    }
    100% {
        -moz-transform: rotate(0deg);
    }
}
@-o-keyframes rotateme {
    0% {
        -o-transform: rotate(0deg);
    }
    50% {
        -o-transform: rotate(45deg);
    }
    100% {
        -o-transform: rotate(0deg);
    }
}

.rotateme-reverse {
    animation-name: rotateme-reverse;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

  -webkit-animation-name: rotateme-reverse;
    -webkit-animation-duration: 30s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

  -moz-animation-name: rotateme-reverse;
    -moz-animation-duration: 30s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

  -ms-animation-name: rotateme-reverse;
    -ms-animation-duration: 30s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

  -o-animation-name: rotateme-reverse;
    -o-animation-duration: 30s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@keyframes rotateme-reverse {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-25deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@-webkit-keyframes rotateme-reverse {
    0% {
        -webkit-transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(-25deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}
@-moz-keyframes rotateme-reverse {
    0% {
        -moz-transform: rotate(0deg);
    }
    50% {
        -moz-transform: rotate(-25deg);
    }
    100% {
        -moz-transform: rotate(0deg);
    }
}
@-o-keyframes rotateme-reverse {
    0% {
        -o-transform: rotate(0deg);
    }
    50% {
        -o-transform: rotate(-25deg);
    }
    100% {
        -o-transform: rotate(0deg);
    }
}

