html, body, #root {
  font-family: $primary-font;
  color: $black;
  height: 100%;
}

code {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: normal;
  margin-bottom: 0.5em;
}

.cognito {
  .c-forms-heading {
    .c-forms-form-title {
      padding-top: 0 !important;
    }
  }

  .c-button-section {
    padding-bottom: 0 !important;
  }
}


